import { API_BASE_URL } from '../config';

// Helper function for API requests
const fetchAPI = async (endpoint, options = {}) => {
    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
        headers: {
            'Content-Type': 'application/json',
            ...options.headers
        },
        ...options
    });

    if (!response.ok) {
        throw new Error(`API error: ${response.status} ${response.statusText}`);
    }

    return await response.json();
};

const fetchFromURL = async (url, options = {}) => {
    const response = await fetch(url, {
        headers: {
            "Content-Type": "application/json",
            ...options.headers
        },
        ...options
    });

    if (!response.ok) {
        throw new Error(`API error: ${response.status} ${response.statusText}`);
    }

    return await response.json();
}

// Data fetching functions
export const api = {
    // Get all projects or filter by category
    getProjects: async (categorySlug) => {
        try {
            if (!categorySlug || categorySlug === 'all') {
                return await fetchAPI('/portfolio/projects/');
            } else {
                return await fetchAPI(`/portfolio/projects/?category=${encodeURIComponent(categorySlug)}`);
            }
        } catch (error) {
            console.error('Error fetching projects:', error);
            throw error;
        }
    },

    // New function to get projects filtered by tag
    getProjectsByTag: async (tag) => {
        try {
            return await fetchAPI(`/portfolio/projects/?tag=${encodeURIComponent(tag)}`);
        } catch (error) {
            console.error('Error fetching projects by tag:', error);
            throw error;
        }
    },

    // Get projects from a specific URL (for pagination)
    getProjectFromUrl: async (url) => {
        try {
            return await fetchFromURL(url);
        } catch (error) {
            console.error('Error fetching projects from URL:', error);
            throw error;
        }
    },

    // Get project categories
    getProjectCategories: async () => {
        try {
            return await fetchAPI('/portfolio/project-categories/');
        } catch (error) {
            console.error('Error fetching project categories:', error);
            return [];
        }
    },

    // Get project details
    getProjectDetails: async (projectId) => {
        try {
            return await fetchAPI(`/portfolio/project/${projectId}/`);
        } catch (error) {
            console.error(`Error fetching project details for ID ${projectId}:`, error);
            return null;
        }
    },

    // Get profile/about data
    getAbout: async () => {
        try {
            return await fetchAPI('/about/profile/1/');
        } catch (error) {
            console.error('Error fetching about:', error);
            return null;
        }
    }
};

export default api;


