import React, { useState } from 'react';
import defaultImage from '../assets/images/logo.svg'; // Zastępczy obraz jeśli brak miniatury

function ProjectCard({
                         title = "Title",
                         description = "",
                         liveLink,
                         repoLink,
                         imageLink,
                         details_link
                     }) {
    const [isHovered, setIsHovered] = useState(false);
    const [imageError, setImageError] = useState(false);

    // Obsługa błędu ładowania obrazka
    const handleImageError = () => {
        setImageError(true);
    };

    return (
        <div
            className={`Gallery-element ${isHovered ? 'Gallery-element-hovered' : ''}`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div className="Gallery-element-header">
                <h2>{title}</h2>
            </div>
            <div className="Gallery-element-content">
                <div className="Gallery-element-image-container">
                    <img
                        src={imageError ? defaultImage : imageLink || defaultImage}
                        alt={title}
                        onError={handleImageError}
                    />
                    {isHovered && description && (
                        <>
                            <div className="Gallery-element-overlay">
                                <p>{description}</p>
                            </div>

                        </>
                    )}
                </div>
                {/* Dodajemy klasę mobile-visible dla buttonów widocznych na małych ekranach */}
                {details_link && (
                    <div className={`Gallery-element-overlay-link ${isHovered ? '' : 'mobile-visible'}`}>
                        <a href={details_link} className="project-details-btn">
                            Open
                        </a>
                    </div>
                )}
                {!isHovered && description && (
                    <div className="Gallery-element-description">
                        <p>{description.length > 80 ? `${description.substring(0, 80)}...` : description}</p>

                    </div>
                )}
            </div>
            <div className="Gallery-element-footer">
                {liveLink && (
                    <a href={liveLink} target="_blank" rel="noopener noreferrer" className="App-link">
                        Live demo
                    </a>
                )}
                {repoLink && (
                    <a href={repoLink} target="_blank" rel="noopener noreferrer" className="App-link">
                        code repo
                    </a>
                )}
                {!liveLink && !repoLink && (
                    <span className="no-links">No links available</span>
                )}
            </div>
        </div>
    );
}

export default ProjectCard;