import React, { useState, useEffect } from 'react';
import logo from '../assets/images/logo.svg';
import IconNavigation from "./IconNavigation"; // Dostosuj ścieżkę

function HomeNavbar() {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [navbarVisible, setNavbarVisible] = useState(false);

    // Słuchaj zdarzenia przewijania
    useEffect(() => {
        const handleScroll = () => {
            const position = window.pageYOffset;
            setScrollPosition(position);

            // Pokaż navbar gdy użytkownik przewinie stronę do pewnego punktu (np. 300px)
            if (position > 300 && !navbarVisible) {
                setNavbarVisible(true);
            } else if (position <= 300 && navbarVisible) {
                setNavbarVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [navbarVisible]);

    // Oblicz progres animacji (wartość od 0 do 1) na podstawie pozycji przewijania
    const scrollProgress = Math.min(scrollPosition / 300, 1);

    // Dodajemy klasę dla lepszego zarządzania na urządzeniach mobilnych
    const navbarClass = `Top-nav ${navbarVisible ? 'Top-nav-visible' : ''} ${window.innerWidth <= 768 ? 'Top-nav-mobile' : ''}`;

    return (
        <div className={navbarClass}
             style={{
                 opacity: scrollProgress,
                 transform: `translateY(${navbarVisible ? '0' : '-100%'})`
             }}>
            <div className="caption">
                <h1>deos.work</h1>
                <img src={logo} className="App-logo-small" alt="logo" />
                <p>it`s just like you read</p>
            </div>
            <div className="Top-nav-navigation">
                <IconNavigation />
            </div>
        </div>
    );
}

export default HomeNavbar;