import React from 'react';
import WelcomeSection from '../components/WelcomeSection';
// import About from '../components/About';
import ProjectList from '../components/ProjectList';
// import Contact from '../components/Contact';
import Footer from '../components/Footer';
import HomeNavbar from '../components/HomeNavbar';

function HomePage() {
    return (
        <div className="App">
            <HomeNavbar />
            <WelcomeSection />

            <main>
                <ProjectList />
            </main>
            <Footer />
        </div>
    );
}

export default HomePage;
