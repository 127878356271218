import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/images/logo.svg'; // Dostosuj ścieżkę
import IconNavigation from './IconNavigation';

function Header({title="deos.work"}) {
    const navigate = useNavigate();

    const navigateToSection = (e, sectionId) => {
        e.preventDefault();

        const isOnHomePage = window.location.pathname === '/';

        if (isOnHomePage) {
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            // Jeśli nie jesteśmy na stronie głównej, najpierw nawiguj do strony głównej
            navigate('/');
            setTimeout(() => {
                const section = document.getElementById(sectionId);
                if (section) {
                    section.scrollIntoView({ behavior: 'smooth' });
                }
            }, 100); // Małe opóźnienie, aby strona zdążyła się załadować
        }
    };

    // Słuchaj zdarzenia przewijania

    return (
        <div className="Top-nav-underlay">
            <div className={`Top-nav Top-nav-relative`}
            >
                <div className="caption">
                    <div className="logo-container">
                        <img src={logo} className="App-logo-small-left" alt="logo" />
                        <span className="website-url">www.deos.work</span>
                    </div>
                    <h1 className="title">{title}</h1>
                </div>

                <div className="Top-nav-navigation">
                    <IconNavigation navigateToSection={navigateToSection} />
                </div>
            </div>

        </div>

    );
}

export default Header;
