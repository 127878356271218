import React, { createContext, useState, useContext } from 'react';
import ContactForm from './ContactForm';

// Create a context for the contact form
const ContactFormContext = createContext();

// Create a provider component
export function ContactFormProvider({ children }) {
    const [isContactFormOpen, setIsContactFormOpen] = useState(false);

    const openContactForm = (e) => {
        if (e) e.preventDefault();
        setIsContactFormOpen(true);
    };

    const closeContactForm = () => {
        setIsContactFormOpen(false);
    };

    return (
        <ContactFormContext.Provider value={{ openContactForm, closeContactForm }}>
            {children}
            <ContactForm isOpen={isContactFormOpen} onClose={closeContactForm} />
        </ContactFormContext.Provider>
    );
}

// Create a custom hook to use the contact form context
export function useContactForm() {
    const context = useContext(ContactFormContext);
    if (!context) {
        throw new Error('useContactForm must be used within a ContactFormProvider');
    }
    return context;
}