import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import api from '../services/api';
import './ProjectDetailPage.css';

function ProjectDetailPage() {
    const { projectId } = useParams();
    const navigate = useNavigate();
    const [project, setProject] = useState(null);
    const [loading, setLoading] = useState(true);
    const [activeImage, setActiveImage] = useState(null);

    const handleBackToProjects = (e) => {
        e.preventDefault();
        navigate('/');
        setTimeout(() => {
            const projectsSection = document.getElementById('projects');
            if (projectsSection) {
                projectsSection.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    };

    // Handle clicking on a tag to filter projects
    const handleTagClick = (tag, e) => {
        e.preventDefault();

        // Force a refresh if already on the home page by adding a timestamp
        // This ensures the ProjectList component remounts and handles the new tag parameter
        if (window.location.pathname === '/') {
            navigate(`/?tag=${encodeURIComponent(tag)}&t=${Date.now()}`);
        } else {
            navigate(`/?tag=${encodeURIComponent(tag)}`);
        }

        setTimeout(() => {
            const projectsSection = document.getElementById('projects');
            if (projectsSection) {
                projectsSection.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    };

    useEffect(() => {
        const fetchProject = async () => {
            setLoading(true);
            try {
                const projectDetailData = await api.getProjectDetails(projectId);

                const projectData = {
                    id: projectDetailData.id,
                    title: projectDetailData.title,
                    description: projectDetailData.detailed_description,
                    imageLink: projectDetailData.header_image,
                    images: projectDetailData.images.map(image => ({
                        url: image.image,
                        caption: image.caption
                    })),
                    liveLink: projectDetailData.live_page,
                    repoLink: projectDetailData.github_repo,
                    tags: projectDetailData.tags.map(tag => ({
                        name: tag.name,
                        slug: tag.slug,
                    })),
                    date: new Date(projectDetailData.created_at).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                    }),
                };

                setProject(projectData);
                // Set the header image as the initial active image if there are no gallery images
                if (projectData.images.length === 0) {
                    setActiveImage({ url: projectData.imageLink, caption: projectData.title });
                } else {
                    setActiveImage(projectData.images[0]);
                }
                setLoading(false);
            } catch (error) {
                console.error("Error fetching project data:", error);
                setLoading(false);
            }
        };

        fetchProject();
    }, [projectId]);

    const handleImageClick = (image) => {
        setActiveImage(image);
    };

    if (loading) {
        return (
            <div className="app">
                <Header />
                <main className="project-detail-container loading-container">
                    <div className="loader"></div>
                    <p>Loading project details...</p>
                </main>
                <Footer />
            </div>
        );
    }

    if (!project) {
        return (
            <div className="app">
                <Header />
                <main className="project-detail-container error-container">
                    <div className="error-message">
                        <h2>Project Not Found</h2>
                        <p>Project with ID: {projectId} doesn't exist</p>
                        <Link to="/" className="btn back-button">Back to Portfolio</Link>
                    </div>
                </main>
                <Footer />
            </div>
        );
    }

    return (
        <div className="app">
            <Header title={project.title}/>
            <main className="project-detail-container">
                <div className="breadcrumbs">
                    <Link to="/#projects">Portfolio</Link> / <span>{project.title}</span>
                </div>

                <div className="project-detail-header">
                    <h2>{project.title}</h2>
                    <div className="project-detail-meta">
                        <span className="project-date">{project.date}</span>
                        {project.tags && project.tags.length > 0 && (
                            <div className="project-technologies">
                                {project.tags.map((tech, index) => (
                                    <a
                                        key={index}
                                        href={`/?tag=${encodeURIComponent(tech.name)}`}
                                        className="technology-tag"
                                        onClick={(e) => handleTagClick(tech.name, e)}
                                    >
                                        {tech.name}
                                    </a>
                                ))}
                            </div>
                        )}
                    </div>
                </div>

                <div className="project-detail-content">
                    <div className="project-media-section">
                        <div className="project-main-image">
                            {activeImage && (
                                <img
                                    src={activeImage.url}
                                    alt={activeImage.caption || project.title}
                                    className="featured-image"
                                />
                            )}
                            {activeImage && activeImage.caption && (
                                <p className="image-caption">{activeImage.caption}</p>
                            )}
                        </div>

                        {project.images && project.images.length > 0 && (
                            <div className="project-thumbnails">
                                {/* Include header image in thumbnails */}
                                <div
                                    className={`thumbnail-item ${activeImage.url === project.imageLink ? 'active' : ''}`}
                                    onClick={() => handleImageClick({ url: project.imageLink, caption: project.title })}
                                >
                                    <img src={project.imageLink} alt={`${project.title} - main`} />
                                </div>

                                {project.images.map((image, index) => (
                                    <div
                                        key={index}
                                        className={`thumbnail-item ${activeImage.url === image.url ? 'active' : ''}`}
                                        onClick={() => handleImageClick(image)}
                                    >
                                        <img src={image.url} alt={image.caption || `Gallery image ${index + 1}`} />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>

                    <div className="project-info-section">
                        <div className="project-description">
                            <h3>Project Description</h3>
                            <div className="description-content">
                                {project.description.split('\n').map((paragraph, index) => (
                                    <p key={index}>{paragraph}</p>
                                ))}
                            </div>
                        </div>

                        <div className="project-links">
                            {project.liveLink && (
                                <a href={project.liveLink} target="_blank" rel="noopener noreferrer" className="btn live-link">
                                    <span className="icon">🌐</span> View Live Demo
                                </a>
                            )}
                            {project.repoLink && (
                                <a href={project.repoLink} target="_blank" rel="noopener noreferrer" className="btn repo-link">
                                    <span className="icon">💻</span> View GitHub Repository
                                </a>
                            )}
                        </div>
                    </div>
                </div>

                <div className="project-navigation">
                    <a href="/" onClick={handleBackToProjects} className="btn back-button">
                        <span className="icon">←</span> Back to Projects
                    </a>
                </div>
            </main>
            <Footer />
        </div>
    );
}

export default ProjectDetailPage;