import React, { useState } from 'react';
import { Home, Briefcase, Mail, UserCircle } from 'lucide-react';
import './IconNavigation.css';
import { useContactForm } from './ContactFormContext';

function IconNavigation({ navigateToSection = null }) {
    const [hoveredItem, setHoveredItem] = useState(null);
    const { openContactForm } = useContactForm();

    const navItems = [
        { id: 'projects', label: 'Projects', icon: Briefcase },
    ];

    const handleClick = (e, id) => {
        if (navigateToSection) {
            e.preventDefault();
            navigateToSection(e, id);
        }
        // If navigateToSection doesn't exist, default href="#id" behavior will occur
    };

    return (
        <>
            <div className="icon-nav-container">
                <nav className="icon-nav-menu">
                    <ul>
                        <li key={'main-header'}>
                            <a
                                href="/"
                                className={`icon-nav-link ${hoveredItem === "main-header" ? 'hovered' : ''}`}
                                onMouseEnter={() => setHoveredItem("main-header")}
                                onMouseLeave={() => setHoveredItem(null)}
                            >
                                <div className="icon-container">
                                    <Home className="nav-icon" size={40} />
                                </div>
                                <span className="nav-label">{"Home"}</span>
                            </a>
                        </li>
                        {navItems.map((item) => {
                            const IconComponent = item.icon;
                            // Use href="#id" instead of "/#id" when navigateToSection is not provided
                            // const href = navigateToSection ? `/#${item.id}` : `#${item.id}`;

                            return (
                                <li key={item.id}>
                                    <a
                                        href={"/#" + item.id}
                                        onClick={(e) => handleClick(e, item.id)}
                                        className={`icon-nav-link ${hoveredItem === item.id ? 'hovered' : ''}`}
                                        onMouseEnter={() => setHoveredItem(item.id)}
                                        onMouseLeave={() => setHoveredItem(null)}
                                    >
                                        <div className="icon-container">
                                            <IconComponent className="nav-icon" size={40} />
                                        </div>
                                        <span className="nav-label">{item.label}</span>
                                    </a>
                                </li>
                            );
                        })}
                        <li key={'about'}>
                            <a
                                href="/about"
                                className={`icon-nav-link ${hoveredItem === "about" ? 'hovered' : ''}`}
                                onMouseEnter={() => setHoveredItem("about")}
                                onMouseLeave={() => setHoveredItem(null)}
                            >
                                <div className="icon-container">
                                    <UserCircle className="nav-icon" size={40} />
                                </div>
                                <span className="nav-label">{"About"}</span>
                            </a>
                        </li>
                        <li key={'contact'}>
                            <button
                                onClick={openContactForm}
                                className={`icon-nav-link icon-nav-button ${hoveredItem === "contact" ? 'hovered' : ''}`}
                                onMouseEnter={() => setHoveredItem("contact")}
                                onMouseLeave={() => setHoveredItem(null)}
                            >
                                <div className="icon-container">
                                    <Mail className="nav-icon" size={40} />
                                </div>
                                <span className="nav-label">{"Contact"}</span>
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
        </>
    );
}

export default IconNavigation;