// Footer.jsx with ContactFormContext integration
import React from 'react';
import './Footer.css';
import { useContactForm } from './ContactFormContext';

function Footer() {
    const { openContactForm } = useContactForm();

    return (
        <footer id="contact" className="Contact-section">
            <div className="footer-content">
                <div className="footer-left">
                    <h3>Stay Connected</h3>
                    <p>Feel free to reach out or follow me on social media</p>
                    <div className="contact-button-container">
                        <button onClick={openContactForm} className="contact-button">
                            Send me a message
                        </button>
                    </div>
                </div>

                <div className="Right-social-media">
                    <a
                        href="https://www.linkedin.com/in/dawid-osiecki-b8284858/"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="LinkedIn"
                    >
                        <i className="fab fa-linkedin"></i>
                    </a>
                    <a
                        href="https://www.facebook.com/dawid.osiecki.50"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Facebook"
                    >
                        <i className="fab fa-facebook"></i>
                    </a>
                    <a
                        href="https://github.com/dejvoss#welcome-"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="GitHub"
                    >
                        <i className="fab fa-github"></i>
                    </a>
                    <button
                        onClick={openContactForm}
                        title="Contact me"
                        aria-label="Open contact form"
                        className="social-button"
                    >
                        <i className="fas fa-envelope"></i>
                    </button>
                </div>
            </div>

            <div className="footer-copyright">
                <p>&copy; {new Date().getFullYear()} deos.work. All rights reserved.</p>
            </div>
        </footer>
    );
}

export default Footer;