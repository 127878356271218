import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Briefcase, GraduationCap } from 'lucide-react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import api from '../services/api';
import './AboutPage.css';
import { useContactForm } from '../components/ContactFormContext';

function AboutPage() {
    const [profileData, setProfileData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { openContactForm } = useContactForm();

    useEffect(() => {
        const fetchAboutData = async () => {
            try {
                const data = await api.getAbout();
                setProfileData(data);
            } catch (err) {
                console.error('Error fetching about data:', err);
                setError('Failed to load profile data. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchAboutData();
    }, []);

    // Group skills by category
    const groupedSkills = profileData?.skills?.reduce((acc, skill) => {
        if (!acc[skill.category]) {
            acc[skill.category] = [];
        }
        acc[skill.category].push(skill);
        return acc;
    }, {}) || {};

    // Sort experiences by order or start_date
    const sortedExperiences = profileData?.experiences?.sort((a, b) => {
        if (a.order !== b.order) return b.order - a.order;
        return new Date(b.start_date) - new Date(a.start_date);
    }) || [];

    // Sort education by order or start_date
    const sortedEducation = profileData?.education?.sort((a, b) => {
        if (a.order !== b.order) return b.order - a.order;
        return new Date(b.start_date) - new Date(a.start_date);
    }) || [];

    // Helper function to format dates
    function formatDate(dateString) {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long' });
    }

    // Combined timeline items (both experiences and education)
    const timelineItems = [
        ...sortedExperiences.map(exp => ({
            ...exp,
            type: 'experience',
            dateDisplay: exp.is_current
                ? `${formatDate(exp.start_date)} - Present`
                : `${formatDate(exp.start_date)} - ${formatDate(exp.end_date)}`
        })),
        ...sortedEducation.map(edu => ({
            ...edu,
            type: 'education',
            dateDisplay: edu.is_current
                ? `${formatDate(edu.start_date)} - Present`
                : `${formatDate(edu.start_date)} - ${formatDate(edu.end_date)}`
        }))
    ].sort((a, b) => {
        // First, prioritize current items (is_current flag)
        if (a.is_current && !b.is_current) return -1;
        if (!a.is_current && b.is_current) return 1;

        // If both are current or both are not current, sort by end_date
        const aEndDate = a.is_current ? new Date() : new Date(a.end_date || a.start_date);
        const bEndDate = b.is_current ? new Date() : new Date(b.end_date || b.start_date);

        // Sort by end date (newest first)
        return bEndDate - aEndDate;
    });

    if (loading) {
        return (
            <div className="App">
                <Header title="About Me"/>
                <main className="about-page-container">
                    <div className="loading-container">
                        <div className="loading-spinner"></div>
                        <p>Loading profile information...</p>
                    </div>
                </main>
                <Footer />
            </div>
        );
    }

    if (error) {
        return (
            <div className="App">
                <Header title="About Me"/>
                <main className="about-page-container">
                    <div className="error-container">
                        <p className="error-message">{error}</p>
                        <button onClick={() => window.location.reload()} className="retry-button">
                            Try Again
                        </button>
                    </div>
                </main>
                <Footer />
            </div>
        );
    }

    return (
        <div className="App">
            <Header title="About Me"/>

            <main className="about-page-container">
                <div className="about-header">
                    <h2>{profileData?.name} {profileData?.surname}</h2>
                    <p className="subtitle">{profileData?.job_title}</p>
                </div>

                <div className="about-content">
                    <div className="about-section personal-info">
                        <div className="profile-image-container">
                            {profileData?.profile_image ? (
                                <img
                                    src={profileData.profile_image}
                                    alt={`${profileData.name} ${profileData.surname}`}
                                    className="profile-image"
                                />
                            ) : (
                                <div className="profile-image-placeholder">
                                    {profileData?.name?.[0]}{profileData?.surname?.[0]}
                                </div>
                            )}
                        </div>

                        <div className="bio-content">
                            <h3>Who I Am</h3>
                            {profileData?.introduction ? (
                                profileData.introduction.split('\r\n').map((paragraph, index) => (
                                    <p key={index}>{paragraph}</p>
                                ))
                            ) : (
                                <p>No introduction available</p>
                            )}
                        </div>
                    </div>

                    <div className="about-section skills-section">
                        <h2>My Skills & Expertise</h2>

                        <div className="skills-container">
                            {Object.entries(groupedSkills).map(([category, skills]) => (
                                <div className="skill-category" key={category}>
                                    <h3>{category.charAt(0).toUpperCase() + category.slice(1)}</h3>
                                    <ul className="skills-list">
                                        {skills.map(skill => (
                                            <li className="skill-item" key={skill.id}>
                                                <span className="skill-name">{skill.name}</span>
                                                <div className="skill-bar">
                                                    <div
                                                        className="skill-level"
                                                        style={{ width: `${(skill.proficiency * 10)}%` }}
                                                    ></div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="about-section experience-section">
                        <h2>Experience & Education</h2>

                        <div className="timeline">
                            {timelineItems.map((item) => (
                                <div className="timeline-item" key={`${item.type}-${item.id}`}>
                                    <div className={`timeline-dot ${item.type}-dot`}>
                                        {item.type === 'experience'
                                            ? <Briefcase size={18} />
                                            : <GraduationCap size={18} />
                                        }
                                    </div>
                                    <div className="timeline-connector"></div>
                                    <div className="timeline-date">{item.dateDisplay}</div>
                                    <div className="timeline-content">
                                        <h3>
                                            {item.type === 'experience'
                                                ? item.position
                                                : `${item.degree} in ${item.field_of_study}`
                                            }
                                        </h3>
                                        <p className="timeline-location">
                                            {item.type === 'experience'
                                                ? <>{item.company}, {item.location}</>
                                                : <>{item.institution}, {item.location}</>
                                            }
                                        </p>
                                        <p>{item.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="about-cta">
                    <h2>Interested in working together?</h2>
                    <p>Check out my projects or get in touch to discuss potential collaborations.</p>
                    <div className="cta-buttons">
                        <Link to="/#projects" className="btn primary-btn">View Projects</Link>
                        <Link onClick={openContactForm} className="btn secondary-btn">Contact Me</Link>
                    </div>
                </div>
            </main>

            <Footer />
        </div>
    );
}

export default AboutPage;