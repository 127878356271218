import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import ProjectCard from './ProjectCard';
import api from '../services/api';

function ProjectList() {
    const location = useLocation();
    const [projects, setProjects] = useState([]);
    const [categories, setCategories] = useState([]);
    const [activeCategory, setActiveCategory] = useState('all');
    const [activeTag, setActiveTag] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [nextPageUrl, setNextPageUrl] = useState(null);
    const [hasMore, setHasMore] = useState(true);

    const observer = useRef();

    // Parse URL parameters for tag filtering
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const tagParam = searchParams.get('tag');

        if (tagParam) {
            setActiveTag(tagParam);
            // Reset category when filtering by tag
            setActiveCategory('all');

            // Force load projects by tag immediately
            const loadTagProjects = async () => {
                setIsLoading(true);
                try {
                    const projectsData = await api.getProjectsByTag(tagParam);

                    // Process the API response
                    let projectsArray = [];
                    if (Array.isArray(projectsData)) {
                        projectsArray = projectsData;
                        setNextPageUrl(null);
                        setHasMore(false);
                    } else if (projectsData && typeof projectsData === 'object') {
                        if (Array.isArray(projectsData.results)) {
                            projectsArray = projectsData.results;
                            setNextPageUrl(projectsData.next);
                            setHasMore(!!projectsData.next);
                        } else {
                            projectsArray = Object.values(projectsData).filter(item => typeof item === 'object');
                            setNextPageUrl(null);
                            setHasMore(false);
                        }
                    }

                    setProjects(projectsArray);
                    setError(null);
                } catch (err) {
                    console.error('Error loading projects by tag:', err);
                    setError('Projects cannot be loaded. Try again later.');
                    setProjects([]);
                    setHasMore(false);
                } finally {
                    setIsLoading(false);
                }
            };

            loadTagProjects();
        } else {
            setActiveTag(null);
        }
    }, [location.search]);

    // Function to load more projects
    const loadMoreProjects = useCallback(async () => {
        if (!nextPageUrl || isLoading) return;

        setIsLoading(true);
        try {
            const moreProjectsData = await api.getProjectFromUrl(nextPageUrl);

            const moreProjectsArray = Array.isArray(moreProjectsData.results)
                ? moreProjectsData.results
                : [];

            setProjects(prevProjects => [...prevProjects, ...moreProjectsArray]);
            setNextPageUrl(moreProjectsData.next);

            if (!moreProjectsData.next) {
                setHasMore(false);
            }
        } catch (err) {
            console.error('Error loading more projects:', err);
        } finally {
            setIsLoading(false);
        }
    }, [nextPageUrl, isLoading]);

    // Reference for the intersection observer
    const lastProjectElementRef = useCallback(node => {
        if (isLoading || !hasMore) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                loadMoreProjects();
            }
        });
        if (node) observer.current.observe(node);
    }, [isLoading, hasMore, loadMoreProjects]);

    // Load projects based on active filters (category and/or tag)
    const loadFilteredProjects = useCallback(async () => {
        setIsLoading(true);
        try {
            let projectsData;
            if (activeTag) {
                projectsData = await api.getProjectsByTag(activeTag);
            } else {
                projectsData = await api.getProjects(activeCategory !== 'all' ? activeCategory : null);
            }

            // Process the API response
            let projectsArray = [];

            if (Array.isArray(projectsData)) {
                projectsArray = projectsData;
                setNextPageUrl(null);
                setHasMore(false);
            } else if (projectsData && typeof projectsData === 'object') {
                if (Array.isArray(projectsData.results)) {
                    projectsArray = projectsData.results;
                    setNextPageUrl(projectsData.next);
                    setHasMore(!!projectsData.next);
                } else {
                    projectsArray = Object.values(projectsData).filter(item => typeof item === 'object');
                    setNextPageUrl(null);
                    setHasMore(false);
                }
            }

            setProjects(projectsArray);
            setError(null);
        } catch (err) {
            console.error('Error loading projects:', err);
            setError('Projects cannot be loaded. Try again later.');
            setProjects([]);
            setHasMore(false);
        } finally {
            setIsLoading(false);
        }
    }, [activeCategory, activeTag]);

    // Fetch categories
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const categoriesData = await api.getProjectCategories();
                let categoriesArray = [];

                if (Array.isArray(categoriesData)) {
                    categoriesArray = categoriesData;
                } else if (categoriesData && typeof categoriesData === 'object' && Array.isArray(categoriesData.results)) {
                    categoriesArray = categoriesData.results;
                }

                setCategories(categoriesArray);
            } catch (error) {
                console.error('Failed to fetch categories:', error);
            }
        };

        fetchCategories();
    }, []);

    // Load filtered projects when category changes
    // (tag filtering is handled separately in the tag URL parameter effect)
    useEffect(() => {
        // Only load projects by category when there's no active tag
        if (!activeTag) {
            loadFilteredProjects();
        }
    }, [activeCategory, loadFilteredProjects, activeTag]);

    // Handle category change
    const handleCategoryChange = (category) => {
        setActiveCategory(category);
        // Clear tag filter when changing category
        if (activeTag) {
            // Update URL to remove tag parameter
            const url = new URL(window.location);
            url.searchParams.delete('tag');
            window.history.pushState({}, '', url);
            setActiveTag(null);
        }
    };

    // Handle clearing tag filter
    const clearTagFilter = () => {
        // Update URL to remove tag parameter
        const url = new URL(window.location);
        url.searchParams.delete('tag');
        window.history.pushState({}, '', url);
        setActiveTag(null);
    };

    // Loading indicator for initial load
    if (isLoading && projects.length === 0) {
        return (
            <section id="projects" className="Project-gallery">
                <h2 className="section-header">My Projects</h2>
                <div className="loading-container">
                    <div className="loading-spinner"></div>
                    <p>Loading projects...</p>
                </div>
            </section>
        );
    }

    // Error message
    if (error) {
        return (
            <section id="projects" className="Project-gallery">
                <h2 className="section-header">My Projects</h2>
                <div className="error-container">
                    <p className="error-message">{error}</p>
                    <button onClick={() => window.location.reload()} className="retry-button">
                        Try again
                    </button>
                </div>
            </section>
        );
    }

    return (
        <section id="projects" className="Project-gallery">
            <h2 className="section-header">My Projects</h2>

            {/* Active tag filter indicator */}
            {activeTag && (
                <div className="active-filter">
                    <p>Filtering by tag: <span className="highlight-tag">{activeTag}</span></p>
                    <button className="clear-filter-btn" onClick={clearTagFilter}>
                        Clear filter
                    </button>
                </div>
            )}

            {/* Category filters - display only if we have categories and no active tag filter */}
            {categories.length > 0 && !activeTag && (
                <div className="project-categories">
                    <button
                        className={`category-button ${activeCategory === 'all' ? 'active' : ''}`}
                        onClick={() => handleCategoryChange('all')}
                    >
                        All
                    </button>
                    {categories.map((category, index) => (
                        <button
                            key={category.slug || index}
                            className={`category-button ${activeCategory === category.slug ? 'active' : ''}`}
                            onClick={() => handleCategoryChange(category.slug)}
                        >
                            {category.name}
                        </button>
                    ))}
                </div>
            )}

            {!isLoading && projects.length === 0 ? (
                <p className="no-projects">
                    {activeTag ? `No projects found with tag "${activeTag}".` : 'No projects found.'}
                </p>
            ) : (
                <>
                    <div className="Project-gallery-grid">
                        {projects.map((project, index) => {
                            // Last element gets the observer ref
                            if (index === projects.length - 1 && hasMore) {
                                return (
                                    <div ref={lastProjectElementRef} key={project.id || index}>
                                        <ProjectCard
                                            title={project.title || "Untitled Project"}
                                            description={project.description || project.short_description || ""}
                                            liveLink={project.live_page}
                                            repoLink={project.github_repo}
                                            imageLink={project.header_image}
                                            details_link={`/project/${project.id}`}
                                        />
                                    </div>
                                );
                            } else {
                                return (
                                    <ProjectCard
                                        key={project.id || index}
                                        title={project.title || "Untitled Project"}
                                        description={project.description || project.short_description || ""}
                                        liveLink={project.live_page}
                                        repoLink={project.github_repo}
                                        imageLink={project.header_image}
                                        details_link={`/project/${project.id}`}
                                    />
                                );
                            }
                        })}
                    </div>

                    {/* Loading indicator for additional pages */}
                    {isLoading && projects.length > 0 && (
                        <div className="loading-more">
                            <div className="loading-spinner-small"></div>
                            <p>Loading more projects...</p>
                        </div>
                    )}

                    {/* No more projects message */}
                    {!hasMore && projects.length > 0 && (
                        <p className="no-more-projects">All projects are loaded.</p>
                    )}
                </>
            )}
        </section>
    );
}

export default ProjectList;