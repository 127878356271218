import React, { useState } from 'react';
import './ContactForm.css';
import { X } from 'lucide-react';
import { API_BASE_URL } from '../config';

function ContactForm({ isOpen, onClose }) {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitStatus, setSubmitStatus] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            const response = await fetch(`${API_BASE_URL}/contact/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData)
            });

            // Sprawdź typ zawartości odpowiedzi
            const contentType = response.headers.get('content-type');

            if (contentType && contentType.includes('application/json')) {
                // Jeśli odpowiedź to JSON, sparsuj ją
                const data = await response.json();
                console.log('Response data:', data);

                if (response.ok) {
                    setSubmitStatus('success');
                    // reszta kodu obsługi sukcesu...
                } else {
                    setSubmitStatus('error');
                    console.error('Server error:', data);
                }
            } else {
                // Jeśli odpowiedź nie jest JSON-em, użyj tekstu
                const text = await response.text();
                console.error('Received non-JSON response:', text);
                setSubmitStatus('error');
            }
        } catch (error) {
            console.error('Form submission error:', error);
            setSubmitStatus('error');
        } finally {
            setIsSubmitting(false);
        }
    };
    // Prevent scrolling when modal is open
    React.useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <div className={`contact-modal ${isOpen ? 'open' : ''}`}>
            <div className="contact-overlay" onClick={onClose}></div>

            <div className="contact-modal-content">
                <button className="close-modal-btn" onClick={onClose}>
                    <X size={24} />
                </button>

                <div className="contact-form-container">
                    <h2>Get In Touch</h2>
                    <p className="contact-subtitle">Feel free to reach out with any questions or project inquiries.</p>

                    {submitStatus === 'success' ? (
                        <div className="submit-message success">
                            <h3>Message Sent!</h3>
                            <p>Thanks for reaching out. I'll get back to you soon.</p>
                        </div>
                    ) : submitStatus === 'error' ? (
                        <div className="submit-message error">
                            <h3>Something went wrong</h3>
                            <p>Please try again later or contact me directly via email.</p>
                        </div>
                    ) : (
                        <form onSubmit={handleSubmit} className="contact-form">
                            <div className="form-group">
                                <label htmlFor="name">Name</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                    placeholder="Your name"
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                    placeholder="Your email address"
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="message">Message</label>
                                <textarea
                                    id="message"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    required
                                    placeholder="How can I help you?"
                                    rows={5}
                                />
                            </div>

                            <button
                                type="submit"
                                className="submit-btn"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? 'Sending...' : 'Send Message'}
                            </button>
                        </form>
                    )}

                    <div className="contact-alternative">
                        <p>Or reach me directly via:</p>
                        <a href="mailto:support@deos.work" className="email-link">
                            support@deos.work
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactForm;