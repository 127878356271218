import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-scroll';
import logo from '../assets/images/logo.svg'; // Dostosuj ścieżkę

function WelcomeSection() {
    const [scrollPosition, setScrollPosition] = useState(0);
    const headerRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const position = window.pageYOffset;
            setScrollPosition(position);
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Oblicz progres animacji (wartość od 0 do 1)
    const scrollProgress = Math.min(scrollPosition / 300, 1);

    // Wartości transformacji zależą od postępu przewijania
    const logoScale = 1 - (scrollProgress * 0.6); // Logo zmniejsza się do 40% początkowego rozmiaru
    const logoOpacity = 1 - (scrollProgress * 0.7); // Logo staje się mniej widoczne, gdy pojawia się w navbarze

    return (
        <header id="main-header" className="App-header" ref={headerRef}>
            <div className="welcome-content" style={{ opacity: logoOpacity }}>
                <img
                    src={logo}
                    className="App-logo"
                    alt="logo"
                    style={{
                        transform: `scale(${logoScale})`,
                    }}
                />
                <h1>deos.work</h1>
                <p>it`s just like you read</p>
                <p>it`s deos work</p>
                <Link to="Project-gallery" smooth={true} duration={1000} className="App-link">
                    Browse my projects
                </Link>
            </div>
        </header>
    );
}

export default WelcomeSection;